@import "../../../assets/sass/helper";



.dsn-btn-popup {
  position: relative;

  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 25px;

    &, path {
      fill: $heading-color;
    }
  }
}
