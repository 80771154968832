@import "../../assets/sass/helper";

.next-page {
  position: relative;
  background-color: $assistant-color;
  overflow: hidden;

  &:before {
    content: "";
    width: 0;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    background-color: $bg-color;
    transition: width 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  }

  .c-wrap {
    width: 100%;
    display: flex;
    align-items: center;

    a {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      overflow: hidden;

      @include media_768(min) {
        flex-flow: row;
        justify-content: center;
        align-items: center;
      }
      @include media_768() {
        flex-direction: column;
        .career {
          margin-top: 10px;
        }
      }

      .hiring,
      .career {
        position: relative;
        display: block;
        @include media_768(min) {
          width: 50%;
          &.career {
            text-align: right;
          }
        }
      }

      .hiring {
        font-size: 42px;
        font-weight: 800;
        line-height: 1.2;
        letter-spacing: 0.01em;
        color: $line-color;
        text-transform: uppercase;
        transition: color 0.5s cubic-bezier(0.77, 0, 0.175, 1);
      }

      .career {
        color: $heading-color;
        font-size: 14px;
      }
    }
  }

  &:hover {
    &:before {
      width: 100%;
    }

    a {
      .hiring {
        color: $heading-color;
      }
    }
  }
}

// have development projects
.HaveDevelopmentProject {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: left;
  margin-top: 50px;
  margin-bottom: 50px;
  max-width: 1200px;
  width: 100%;
  gap: 20px;

  &__Title {
    font-size: 30px;
    font-weight: 600;
    color: #000;
    margin-bottom: 20px;
  }
  & span {
    font-size: 1rem;
  }
  .happyClient {
    font-size: 1rem;
    color: #fff;
    &:hover {
      color: #fff;
    }
  }

  &__Reviewsbtn {
    background: #666 !important;
    color: #fff;
    border: none !important;
    text-align: center;
    padding: 10px 20px !important;
    border-radius: 0px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      background: #fff;
      color: #000;
    }
  }
  &__Reviews__Stars {
    display: flex;
    gap: 10px;
    padding-bottom: 5px;
  }

  &__Reviews__Count {
    font-size: 1rem !important;
  }

  @include respond-above(md) {
    flex-direction: row;
  }
}
