@import "../../assets/sass/helper";
.wrapper-brand{

    .brand-item{
        position: relative;
        display: flex;
        align-items: center;
        text-align: center;
        min-height: 240px;
        padding: 15px;
    }

    .box-img {
        margin: auto;
        img {
            max-width: 230px;
            margin: auto;
        }
    }
    .info {
        top: 0;
        z-index: 2;

        & , .content , .entry{
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .content {
            bottom: 0;
            width: 40px;
            height: 40px;
            border-radius: 0 20px 0 0;
            display: inline-block;
            text-align: center;
            transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
            background: $bg-color;
            transition-delay: 0.3s;


            .icon {
                position: relative;
                color: $heading-color;
                font-size: 10px;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
            }

            .entry {
                padding: 10%;

                display: flex;
                vertical-align: middle;
                justify-content: center;
                align-items: center;
                transition: opacity 0.3s cubic-bezier(0.77, 0, 0.175, 1);
                opacity: 0;

                div {
                    width: 100%;

                    h5 {
                        font-size: 15px;
                        margin-bottom: 20px;
                    }

                    a {
                        letter-spacing: 2px;
                        font-size: 16px;
                    }
                }
            }

            &:hover {
                width: 100%;
                height: 100%;
                border-radius: 0;
                transition-delay: 0s;


                .icon {
                    display: none;
                    opacity: 0;
                    visibility: hidden;
                }

                .entry {
                    left: 0;
                    top: 0;
                    opacity: 1;
                    transition-delay: 0.3s;
                }
            }
        }
    }
}

