@import "../../assets/sass/helper";

.footer {
  position: relative;



  .dsn-col-footer {
    .footer-title {
      position: relative;
      color: $heading-color;
      text-transform: uppercase;
      font-size: 15px;
      padding-bottom: 10px;
      margin-bottom: 25px;
      letter-spacing: 2px;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 15px;
        height: 2px;
        background-color: $line-color;
      }
    }

    ul.col-menu li {
      font-size: 14px;
      font-weight: 600;
      overflow: hidden;
      &:not(:last-child){
        margin-bottom: 5px;
      }

      a {
        font-weight: 600;
      }
    }


    .col-contact p{
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 10px;

      strong {
        color: $heading-color;
      }

      span {
        margin-right: 5px;
        margin-left: 5px;
      }
    }

  }

  a,
  p {
    color: $body-color;
    margin-bottom: 0;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 4px;
    line-height: 1.6;
    font-variant-numeric: proportional-nums;
    font-family: $heading-font;
  }

  .copyright {
    font-size: 14px;
    letter-spacing: 2.67px;
    border-top: 1px solid $border-color;
    // position: relative;

    p {
      text-transform: uppercase;
    }

    .copyright-text {
      color: $body-color;
      margin-top: 5px;
      font-weight: 500;

      a {
        margin-left: 5px;
        color: $line-color;
      }
    }
  }
  .scroll-to-top{
    position: absolute;
    right: 0;
    top: 90%;
    transform: translateY(-50%);
    z-index: 2;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    font-size: 0;
    transition: all 0.3s ease-out;
    opacity: 0.5;
    &:hover{
      transform: translateY(-50%) scale(1.1);
      opacity: 1;
    }
    &:active{
      transform: translateY(-50%) scale(0.9);
    }


  }

}
